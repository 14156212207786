/**
 * Dashboard page of the application
 * 1. Upload data
 * 2. Download templates
 * 3. Tableau dashboard
 * Created By : Yogeesha Sapaliga
 * Created date : 20/09/2023
 * Modified date : 25/09/2023
 */
import React, { useState, createRef, useEffect, useRef } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Button from '@mui/material/Button';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import Grid from '@mui/material/Grid';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import GridOnIcon from '@mui/icons-material/GridOn';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import { PublicClientApplication } from "@azure/msal-browser";
import { useIsAuthenticated } from "@azure/msal-react";
import { useLocation, useNavigate } from "react-router-dom";

//  Custom imports
import './Dashboard.scss';
import { msalConfig } from '../../../authConfig';
import MiniDrawer from '../../molecules/Drawers/MiniDrawer';
import api from '../../services/api';
import UploadOptionsDialog from '../../molecules/Dialogs/UploadOptions';
import UploadHistory from '../../molecules/Drawers/UploadHistory';

export const msalInstance = new PublicClientApplication(msalConfig);

const Dashboard = (props) => {

    const navigate = useNavigate();
    const vLocation = useLocation();
    
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const [uploadHistoryDisabled, setUploadHistoryDisabled] = useState(true);
    const [dialogType, setDialogType] = useState('upload');
    const [fileType, setFileType] = useState('Loan');
    const [isUploadHistoryOpen, setIsUploadHistoryOpen] = useState(false);
    const [fileName, setFileName] = useState('');
    const [uploadStatus, setUploadStatus] = useState('');
    const [failureFilePath, setFailureFilePath] = useState('');
    const [failureFileName, setFailureFileName] = useState('');
    const [failureMessage, setFailureMessage] = useState('');
    const [noOfValidationErrors, setNoOfValidationErrors] = useState(null);

    //  Adding to avoid async update of useState
    let connectionId = null;

    const isAuthenticated = useIsAuthenticated();

    const uploadRef = createRef();

    //  Environment values
    const validFileFormats = process.env.REACT_APP_ValidFileFormat;
    const validFileSize = process.env.REACT_APP_FileSizeLimit;
    const vWebsocketUrl = process.env.REACT_APP_Websocket_Url;
    const vTDBUrl = process.env.REACT_APP_TDB_Link;

    const [fileFormats, setFileFormats] = useState(validFileFormats);

    const ws = useRef(null);

    useEffect(() => {
        if (isAuthenticated) {
            if (vLocation.state && vLocation.state.hasUploadHistory) {
                setUploadHistoryDisabled(false);
            } else {
                setUploadHistoryDisabled(true);
            }
        } else {
            navigate('/login');
        }
    }, [isAuthenticated]);

    const uploadDialog = (vFileType) => {
        setFileType(vFileType);
        setDialogIsOpen(false);
        uploadRef.current.click();
    }

    const onFileChange = (vEvent) => {
        if (vEvent.target.files && (vEvent.target.files.length > 0)) {

            const file = vEvent.target.files[0];

            setFileName(file.name);

            const extension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
            //  Validate the file format as only XLSX
            const vValidFileFormats = validFileFormats.split(',');
            let vValidFileFormatFound = false;
            for (const el of vValidFileFormats) {
                if (el.toUpperCase() === extension.toUpperCase()) {
                    vValidFileFormatFound = true;
                }
            }
            if ((fileType === 'Forex') && (extension.toUpperCase() !== '.CSV') && (extension.toUpperCase() !== '.XLSX')) {
                setFailureMessage('The file format is wrong. Please make sure you are uploading a .csv file.');
                setDialogIsOpen(true);
                setDialogType('failure');
                document.getElementById('fileInput').value = '';
                return;
            } else if ((fileType !== 'Forex') && (!vValidFileFormatFound)) {
                setFailureMessage('The file format is wrong. Please make sure you are uploading a .xlsx file.');    //  validFileFormats
                setDialogIsOpen(true);
                setDialogType('failure');
                document.getElementById('fileInput').value = '';
                return;
            }

            //  Validate for size less than 5MB
            const vSizeLimitMB = Math.round(validFileSize / 1000000, 0);
            if (file.size > validFileSize) {
                setFailureMessage('The file uploaded is exceeding the maximum size of ' + vSizeLimitMB + ' Mb.  Please check that only the data strictly required by the dashboard has been inserted. Please don\'t include pictures, videos and charts.');
                setDialogIsOpen(true);
                setDialogType('failure');
                document.getElementById('fileInput').value = '';
                return;
            }

            setDialogIsOpen(true);
            setDialogType('progress');

            const varData = JSON.stringify({
                fileName: file.name,
                fileType: fileType
            });

            const varHeaders = {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            };

            setUploadStatus('Uploading file...');

            connectWS();

            api.sendFileDetails(varData, varHeaders).then(res => {
                if (res) {
                    const vUploadUrl = res.uploadPath;
                    const vFileName = res.fileName;
                    const reader = new FileReader();
                    reader.onloadend = (event) => {
                        const vheaders = {
                            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                        };
                        api.sendFile(vUploadUrl, event.target.result, vheaders).then(resUpload => {
                            if (resUpload && connectionId) {
                                const varData = {
                                    fileName: vFileName,
                                    fileType: fileType,
                                    connectionId: connectionId
                                };

                                setUploadStatus('Initiated basic validation...');
                    
                                api.processFile(varData, varHeaders).then(resValidate => {
                                    if (resValidate && resValidate.processStatus) {
                                        const vProcessStatus = resValidate.processStatus;
                                        const vProcessStatusUCase = resValidate.processStatus.toUpperCase();
                                        if (vProcessStatusUCase.indexOf('INITIATED DATA VALIDATION') !== -1) {
                                            document.getElementById('fileInput').value = '';
                                            setUploadHistoryDisabled(false);
                                            setUploadStatus(vProcessStatus);
                                        } else {
                                            console.log('ERROR: Basic Validation check failed');
                                            processFailureMessage(vProcessStatus);
                                            displayFailure();
                                        }
                                    } else {
                                        console.log('ERROR: Validation');
                                        displayFailure();
                                    }
                                }).catch(error => {
                                    console.log('ERROR: Validation ', error);
                                    displayFailure();
                                });
                            } else {
                                displayFailure();
                            }
                        }).catch(error => {
                            displayFailure();
                        });
                    };
                    reader.readAsArrayBuffer(file);
                } else {
                    displayFailure();
                }
            }).catch(error => {
                displayFailure();
            });
        }
    }

    const displayFailure = () => {
        setDialogIsOpen(true);
        setDialogType('failure');
        document.getElementById('fileInput').value = '';
        connectionId = null;
        if (ws.current) {
            console.log("Closing WS, displayFailure");
            //ws.current.close();
        }
    }

    const handleUploadHistoryOpen = () => {
        setIsUploadHistoryOpen(true);
    };

    const handleUploadHistoryClose = () => {
        setIsUploadHistoryOpen(false);
    };
    
    /**
     * Function to redirect to Tableau Dashboard
     */
    const viewTableauDashboard = () => {
        if (vTDBUrl) {
            window.open(vTDBUrl, '_blank', 'noreferrer');
        }
    }

    const onUploadOptionsDialogClose = () => {
        setDialogType('upload');
        setDialogIsOpen(false);
        setFailureMessage('');
        setFileName('');
        setFailureFilePath('');
        setFailureFileName('');
        setUploadStatus('');
        setNoOfValidationErrors(null);
        connectionId = null;
        if (ws.current) {
            console.log("Closing WS, onUploadOptionsDialogClose");
            ws.current.close();
        }
    }

    const reOpenUploadOptions = () => {
        setDialogType('upload');
        setDialogIsOpen(true);
        setFailureMessage('');
        setFileName('');
        setFailureFilePath('');
        setFailureFileName('');
        setUploadStatus('');
        setNoOfValidationErrors(null);
        connectionId = null;
        if (ws.current) {
            console.log("Closing WS, reOpenUploadOptions");
            //ws.current.close();
        }
    }

    const connectWS = () => {
        ws.current = new WebSocket(vWebsocketUrl);
        ws.current.onopen = () => {
            ws.current.send('Get Connection Id');
            console.log("Websocket is connected.");
        }
        ws.current.onclose = () => {
            console.log("Websocket is closed.");
        }
        ws.current.onerror = (e) => {
            console.log('ERROR in Websocket : ', e);
            if (ws.current.readyState === 1) {
                connectionId = null;
                console.log("Closing WS, onerror");
                //setTimeout(function() {ws.current.close();}, 1000);
            }
        }
        ws.current.onmessage = e => {
            const res = JSON.parse(e.data);
            const vMessage = res.message;
            if ((res.statusCode === 200) && (res.connectionId)) {
                const vConnectionId = res.connectionId;
                connectionId = vConnectionId;
            } else if (res.statusCode === 201) {
                setDialogIsOpen(true);
                setDialogType('success');
                connectionId = null;
                console.log("Closing WS, onmessage res.statusCode === 201");
                //setTimeout(function() {ws.current.close();}, 1000);
            } else if (res.statusCode === 202) {
                const vStatus = vMessage.uploadStatus.toUpperCase();
                if (vStatus !== 'UPLOAD IN PROGRESS') {
                    setUploadStatus(vMessage.uploadStatus);
                }
            } else if ((res.statusCode === 400) || (res.statusCode === 500)) {
                setFailureMessage('');
                setDialogIsOpen(true);
                setDialogType('failure');
                connectionId = null;
                console.log("Closing WS, onmessage res.statusCode === 400 || 500");
                //setTimeout(function() {ws.current.close();}, 1000);
            } else {
                if (vMessage.objectKey && (vMessage.objectKey !== 'None')) {
                    setFailureFilePath(vMessage.objectKey);
                    let vFinalFileName = vMessage.objectKey;
                    if (vFinalFileName.indexOf('/') !== -1) {
                        const vFileNameFull = vFinalFileName.split('/');
                        vFinalFileName = vFileNameFull[vFileNameFull.length-1];
                    }
                    setFailureFileName(vFinalFileName);
                    setNoOfValidationErrors('n');
                }
                const vNoOfErrorsMatch = vMessage.uploadStatus.match(/(\d+)/);
                if (vNoOfErrorsMatch) {
                    setNoOfValidationErrors(vNoOfErrorsMatch[0]);
                }
                const vFailureMessage = (vMessage.objectKey && (vMessage.objectKey !== 'None')) ? '' : vMessage.uploadStatus;
                setFailureMessage(vFailureMessage);
                setDialogIsOpen(true);
                setDialogType('failure');
                connectionId = null;
                console.log("Closing WS, onmessage else");
                //setTimeout(function() {ws.current.close();}, 1000);
            }
        };
    }

    const processFailureMessage = (vProcessStatus) => {
        const vProcessStatusUCase = vProcessStatus.toUpperCase();
        switch (vProcessStatusUCase) {
            case 'TEMPLATE VALIDATION FAILED':
                setFailureMessage('The file structure is wrong. Please make sure you are using one of the latest templates provided.');
                break;
            case 'LOAN ID CHECK FAILED':
                setFailureMessage('Please make sure that the Loan ID provided is the same as the Finance Active one.');
                break;
            case 'LOAN NAME CHECK FAILED':
                setFailureMessage('Please make sure that Loan name has at least 5 characters.');
                break;
            case 'LOAN UNIQUENESS CHECK FAILED':
                setFailureMessage('Another loan is already using this name. Please make sure that Loan ID and Loan Name are correct.');
                break;
            case 'LOAN ID EXISTS CHECK FAILED':
                setFailureMessage('The loan associated to this Property file does not exist in the system. Please, make sure to first upload the Loan input file for this loan, with the correct Loan name and ID.');
                break;
            case 'LOAN IPD EXISTS CHECK FAILED':
                setFailureMessage('This Property file IPD is not in the system. Please, make sure to first upload the Loan input file with the same IPD as the Property file you are trying to upload.');
                break;
            case 'CURRENCY CONSISTENCY CHECK FAILED':
                setFailureMessage('The reporting currency specified in this file is not consistent with the one specified in the Loan input file. Please make sure that the two are the same.');
                break;
            case 'CURRENCY CHECK FAILED':
                setFailureMessage('Please make sure that loan report currency is selected.');
                break;
            case 'IPD DATE CHECK FAILED':
                setFailureMessage('Please make sure a valid IPD is entered.');
                break;
            default:
                setFailureMessage(vProcessStatus);
        }
    }

    const setValidFileFormats = (vFileType) => {
        if (vFileType === 'Forex') {
            setFileFormats(['.csv', '.xlsx']);
        } else {
            setFileFormats(validFileFormats);
        }
    }

    return (
        <Container className="container-dashboard">
            <Typography component="div">
                <Box sx={{ display: 'flex' }}>
                    <CssBaseline />
                    <MiniDrawer />
                    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                        <div className='dashboard-title'>
                            <Grid container spacing={2}>
                                <Grid item md={6} sm={12} data-testid='titleDashboard'>
                                    Dashboard
                                </Grid>
                                <Grid item md={6} sm={12} className='align-right'>
                                        <Button
                                            variant="outlined"
                                            className='upload-history-button'
                                            disabled={uploadHistoryDisabled}
                                            onClick={() => handleUploadHistoryOpen()}
                                            data-testid='btnUploadHistory'>
                                            <HistoryOutlinedIcon />
                                            <span className='upload-history-label'>Upload history</span>
                                        </Button>
                                </Grid>
                            </Grid>
                        </div>
                        <Grid
                            container 
                            spacing={2} 
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            className='mt-10'>
                            <Grid item md={6} sm={6}>
                                <Grid container spacing={2}>
                                    <Grid item md={6} sm={12} className='pr-2'>
                                        <UploadOptionsDialog
                                            type={dialogType}
                                            open={dialogIsOpen}
                                            onClose={onUploadOptionsDialogClose} 
                                            onUpload={uploadDialog}
                                            fileName={fileName}
                                            failureFilePath={failureFilePath}
                                            failureFileName={failureFileName}
                                            failureMessage={failureMessage}
                                            uploadStatus={uploadStatus}
                                            onRetry={reOpenUploadOptions}
                                            noOfValidationErrors={noOfValidationErrors}
                                            onFileTypeChange={setValidFileFormats} />
                                        <input
                                            id='fileInput'
                                            type="file"
                                            ref={uploadRef}
                                            onChange={onFileChange}
                                            className='fileInput'
                                            accept={fileFormats} />
                                        <div className='upload-card addCursor' data-testid='btnUploadNewData'
                                            onClick={() => setDialogIsOpen(true)}>
                                            <FileUploadOutlinedIcon className='dashboard-icon' />
                                            <span className='upload-label'>Upload new data</span>
                                        </div>
                                    </Grid>
                                    <Grid item md={6} sm={12} className='pl-2'>
                                        <div className='upload-card addCursor' data-testid='btnExcelTemplates'
                                            onClick={() => {
                                                setDialogIsOpen(true);
                                                setDialogType('download');
                                            }}>
                                            <GridOnIcon className='dashboard-icon' />
                                            <span className='upload-label'>Excel templates</span>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} className='pt-2'>
                                    <Grid item md={12} sm={12} className='pr-2'>
                                        <div className='upload-card align-center addCursor' data-testid='btnTableauDashboard'
                                            onClick={() => viewTableauDashboard()}>
                                            <TableChartOutlinedIcon className='dashboard-icon' />
                                            <span className='upload-label'>Tableau dashboard</span>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <UploadHistory
                    uploadHistoryOpen={isUploadHistoryOpen}
                    handleUploadHistoryOpen={handleUploadHistoryOpen}
                    handleUploadHistoryClose={handleUploadHistoryClose} />
            </Typography>
        </Container>
    )
}

export default Dashboard