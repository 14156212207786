/**
 * Min drawer for menu items such as dashboard, help etc
 * Created By : Yogeesha Sapaliga
 * Created date : 26/09/2023
 */
import React, { useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import HelpIcon from '@mui/icons-material/Help';
import LogoutIcon from '@mui/icons-material/Logout';
import { useMsal } from '@azure/msal-react';
import RefreshIcon from '@mui/icons-material/Refresh';
import { BsQuestionSquareFill  } from 'react-icons/bs';
import { MdMenuBook  } from 'react-icons/md';

import { snowLink } from '../../utils/constants';
//  Custom imports
import api from '../../services/api';
import CustomSnackbar from '../../atoms/Snackbar/CustomSnackbar';
import ButtonMenu from '../../molecules/Drawers/ButtonMenu';

import './MiniDrawer.scss';
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const vMenuItems = ['Dashboard', 'Help', 'Refresh Tableau', 'Sign Out'];
  const navigate = useNavigate();  
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const { instance, accounts } = useMsal();
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [menuItems, setMenuItems] = useState([
    {
      id: 1,
      href: "/dashboard",
      icon: DashboardIcon,
      name: "Dashboard",
    },
    // {
    //   id: 2,
    //   href: "/help",
    //   icon: HelpIcon,
    //   name: "Help",
    // },
  ]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const signOutClickHandler = (instance) => {
    const logoutRequest = {
      account: accounts[0],
      mainWindowRedirectUri: process.env.REACT_APP_REDIRECT_URL,
      postLogoutRedirectUri: process.env.REACT_APP_REDIRECT_URL,
    };
    instance.logoutPopup(logoutRequest);
  };

  const refreshTableauDashboard = () => {
    api
      .refreshTableau({}, {})
      .then((res) => {
        setIsSnackbarOpen(true);
        setSnackbarType('success');
        setSnackbarMessage('Tableau Dashboard refreshed successfully');
      })
      .catch((error) => {
        console.log('Error occurred while refreshing Tableau dashboard.');
      });
  };

  const redirect = (vIndex, item) => {    
    if (vIndex === 2) {
      refreshTableauDashboard();
    } else if (vIndex === 3) {
      signOutClickHandler(instance);
    }else{
      navigate(item.href);
    }
  };

  return (
    <Drawer
      variant='permanent'
      open={open}
    >
      <DrawerHeader>
        <IconButton
          onClick={!open ? handleDrawerOpen : handleDrawerClose}
          className='menuIconButton'
        >
          {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </DrawerHeader>
      <List>
      {menuItems.map((item, index) => (
            <ListItem
            key={item.name}
            disablePadding
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
              selected={item.href === location.pathname}
              className='ml-1 mr-1 menuIcon'
              onClick={() => redirect(index, item)}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <item.icon />
              </ListItemIcon>
              <ListItemText
                primary={item.name}
                sx={{ opacity: open ? 1 : 0 }}
                className='labelMenu'
              />
            </ListItemButton>
          </ListItem>
          ))}
      </List>
      <div style={{ position: 'absolute', bottom: 0, width: '100%', textAlign: 'center' }}>
        <ButtonMenu isDrawerOpen={open} linkHref={'/help'} icon={<MdMenuBook style={{
            width: 20,
            height: 20,
            color: '#003F2D',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }} />} titleText='How to guide' />
        <ButtonMenu isDrawerOpen={open} linkHref={snowLink} icon={<BsQuestionSquareFill style={{
            width: 20,
            height: 20,
            color: '#003F2D',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }} />} titleText='Create a support ticket' />
      </div>
      <CustomSnackbar
        onClose={() => setIsSnackbarOpen(false)}
        open={isSnackbarOpen}
        type={snackbarType}
        message={snackbarMessage}
      />
    </Drawer>
  );
}
