/**
 * Login page of the application
 * 1. Sign up link
 * 2. Sign in link
 * Created By : Yogeesha Sapaliga
 * Created date : 19/09/2023
 */
import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { useIsAuthenticated } from '@azure/msal-react';

//  Custom imports
import imgLogo from '../../../assets/images/cbre_logo.svg';
import './Login.scss';
import api from '../../services/api';
import ButtonCommon from '../../atoms/Button/ButtonCommon';
import { loginRequest } from '../../../authConfig';
import Loader from '../../atoms/Loader/Loader';
import { snowLink } from '../../utils/constants';

const Login = () => {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const isAuthenticated = useIsAuthenticated();
  const handleSignInWithSSO = async () => {
    //  Redirect to Azure SSO login
    await instance.loginRedirect(loginRequest).catch((error) => {
      console.log('Error occurred while redirecting to login with SSO.');
    });
  };

  useEffect(() => {
    if (isAuthenticated) {
      saveLoginUserDetails();
    }
  }, [isAuthenticated]);
  const saveLoginUserDetails = async () => {
    try {
      setLoading(true);
      //  API call to log user login details
      const varData = {};
      api
        .saveLoginUserDetails(varData, {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        })
        .then((res) => {
          if (res && res.hasOwnProperty('hasUploadHistory')) {
            setLoading(false);
            navigate('/dashboard', { state: { hasUploadHistory: res.hasUploadHistory } });
          } else {
            console.log('Error occurred while receiving upload history flag details.');
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log('Error occurred while uploading login user details.');
          setLoading(false);
          // Do nothing
        });
    } catch (error) {
      console.log('Error occurred while validating user.');
    }
  };
  return (
    <Container className='container-home'>
      <Typography component='div'>
        {loading ? (
          <Grid
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
            style={{ height: '100vh' }}
          >
            <Loader />
          </Grid>
        ) : (
          <Grid
            container
            spacing={1}
          >
            <Grid
              item
              lg={9}
              md={9}
              sm={12}
              className='bgImage'
            ></Grid>
            <Grid
              item
              lg={3}
              md={3}
              sm={12}
            >
              <div className='container-right'>
                <div className='container-logo'>
                  <img
                    src={imgLogo}
                    alt='Logo'
                  />
                </div>
                <div
                  className='h5-headline mt-5'
                  data-testid='LabelSignIn'
                >
                  Sign in
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      fontSize: '16px',
                      fontWeight: '400',
                      lineHeight: '20px',
                      color: '#000000',
                      marginTop: '6px',
                    }}
                  >
                    Need any help?
                  </div>
                  <a
                    href={snowLink}
                    target='_blank'
                    rel='noreferrer'
                    className='help-link'
                    style={{ display: 'flex', alignItems: 'center', padding: '10px' }}
                  >
                    Create a support ticket
                  </a>
                </div>
                {/* <div className=''>
                                <span className='subtitle-1' data-testid='labelDontHaveAccount'>
                                    Don't have an account?
                                </span>
                                <a href="#getone" className='signup-link ml-1'>Get one</a>.
                            </div> */}
                <hr className='divider'></hr>
                <ButtonCommon onClick={handleSignInWithSSO}></ButtonCommon>
              </div>
            </Grid>
          </Grid>
        )}
      </Typography>
    </Container>
  );
};

export default Login;
