/**
 * Function to display all data tables
 * Created By: Yogeesha Sapaliga
 * Created On: 09/10/2023
 */
import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';

//  Custom imports
import './DataTable.scss';

export default function DataTable(props) {
    const { rows, columns, loading } = props;
    return (
        <div style={{ height: 400, width: '100%', marginTop: 64 }}>
            <DataGrid
                rows={rows}
                columns={columns}
                hideFooter={true}
                hideFooterPagination={true}
                className='dataTable'
                loading={loading}
            />
        </div>
    );
}