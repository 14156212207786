import React, { useState } from "react";
import Loader from "../../atoms/Loader/Loader"; 
import { Box, Container, CssBaseline, Typography } from "@mui/material";
import MiniDrawer from "../../molecules/Drawers/MiniDrawer";

function PDFViewer() {
  const [loading, setLoading] = useState(true);

  return (
    <Container className="container-dashboard">
            <Typography component="div">
                <Box sx={{ display: 'flex' }}>
                    <CssBaseline />
                    <MiniDrawer />
                    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                      <div>
                        {loading && <Loader open={loading} />}
                        <iframe
                          src="CBRE Loan Analytics Portal - training guide v2.0.pdf"
                          width="100%"
                          height="700px"
                          style={{ display: loading ? "none" : "block" }}
                          onLoad={() => setLoading(false)}
                        />
                      </div>
                    </Box>
                </Box>
            </Typography>
        </Container>    
  );
}

export default PDFViewer;
