/**
 * Upload options dislaog box
 * Created By : Yogeesha Sapaliga
 * Created date : 27/09/2023
 */
import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';

//  Custom imports
import Loader from '../../atoms/Loader/Loader';
import './UploadOptions.scss';
import iconSuccess from '../../../assets/images/icon_success.svg';
import iconFailure from '../../../assets/images/icon_failure.svg';
import iconValErrors from '../../../assets/images/icon_valerrors.svg';
import api from '../../services/api';
import { snowLink } from '../../utils/constants';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
      minWidth: '500px'
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
}));
  
export default function UploadOptionsDialog(props) {
    const { open, type, onClose, uploadStatus, failureFilePath, failureFileName, failureMessage, onRetry, noOfValidationErrors, onFileTypeChange } = props;
    const [ value, setValue ] = React.useState('Loan');
    const [ isDownloadFileDisabled, setIsDownloadFileDisabled ] = React.useState(false);

    const getFileName = (vFilePath) => {
        let tmpFileName = vFilePath;
        if (tmpFileName.indexOf('/') !== -1) {
            const vFileNameArr = tmpFileName.split('/');
            tmpFileName = vFileNameArr[vFileNameArr.length-1];
        }
        return tmpFileName;
    }

    const vLoanTemplateFilePath = process.env.REACT_APP_Loan_template;
    const vPropertyTemplateFilePath = process.env.REACT_APP_Property_template;
    const vLoanTemplateFileName = getFileName(vLoanTemplateFilePath);
    const vPropertyTemplateFileName = getFileName(vPropertyTemplateFilePath);

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick") 
            return;
        onClose();
    }

    const handleChange = (event) => {
        setValue(event.target.value);
        onFileTypeChange(event.target.value);
    };
    const upload = () => {
        props.onUpload(value);
    }
    const viewTableauDashboard = () => {
        const vTDBUrl = process.env.REACT_APP_TDB_Link;
        if (vTDBUrl) {
            window.open(vTDBUrl, '_blank', 'noreferrer');
        }
    }
    const retryUpload = () => {
        onRetry();
    }

    const downloadFile = (vType) => {
        const vFileName = (vType === 'validation') ? failureFilePath : (vType === 'loan') ? vLoanTemplateFilePath : (vType === 'property') ? vPropertyTemplateFilePath : '';
        const vDownloadFileName = (vType === 'validation') ? failureFileName : (vType === 'loan') ? vLoanTemplateFileName : (vType === 'property') ? vPropertyTemplateFileName : '';
        const varData = {
            'objectKey': vFileName
        };
        const varHeaders = {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
        //  Disable Download file button
        setIsDownloadFileDisabled(true);
        api.getDownloadFileDetails(varData, varHeaders).then(res => {
            if (res && res.downloadPath) {
                const vDownloadUrl = res.downloadPath;
                const vHeaders = {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                };
                api.downloadFile(vDownloadUrl, vHeaders).then(async resDownload => {
                    if (resDownload && resDownload.ok) {
                        const vData = await resDownload.blob();
                        const vContentType = resDownload.headers.get('content-type');
                        const vBlob = new Blob([vData], { type: vContentType });
                        const vUrl = window.URL.createObjectURL(vBlob);
                        const vDownloadLink = document.createElement('a');
                        vDownloadLink.href = vUrl;
                        vDownloadLink.download = vDownloadFileName;
                        vDownloadLink.click();
                        setIsDownloadFileDisabled(false);
                    } else {
                        console.log('ERROR: Downloading file');
                        setIsDownloadFileDisabled(false);
                    }
                }).catch(error => {
                    console.log('ERROR: Downloading file ', error);
                    setIsDownloadFileDisabled(false);
                })
            } else {
                console.log('ERROR: Download file details');
                setIsDownloadFileDisabled(false);
            }
        }).catch(error => {
            console.log('ERROR: Download file details ', error);
            setIsDownloadFileDisabled(false);
        });
    }

    return (
        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            className='upload-options-dialog'
        >
            {
            (type === 'upload') ? 
            (
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" className='upload-options-title'>
                    Upload new data
                </DialogTitle>
            ) : 
            (type === 'success') ?
            (
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" className='upload-options-title align-center'>
                    Upload complete
                </DialogTitle>
            ) :
            (type === 'failure') ?
            (
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" className='upload-options-title align-center'>
                    {noOfValidationErrors ? 'Data validation errors' : 'Data upload has failed'}
                </DialogTitle>
            ) :
            (type === 'download') ?
            (
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" className='upload-options-title align-center'>
                    Download Excel templates
                </DialogTitle>
            ) :
            (
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" className='upload-options-title align-center'>
                    Data upload in progress
                </DialogTitle>
            )}
            <IconButton
                aria-label="close"
                onClick={() => onClose()}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
                className="closeIcon"
            >
            <CloseIcon />
            </IconButton>
            <DialogContent className={
                ((type === 'upload') || (type === 'progress')) ?
                'upload-options-dialog-content lgPadding' :
                'upload-options-dialog-content smPadding'
            }>
                {
                    (type === 'upload') ? 
                    (
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={value}
                            onChange={handleChange}
                            className="uploadFileType"
                        >
                            <FormControlLabel value="Loan" control={<Radio color='success' />} label="Loan file" />
                            <FormControlLabel value="Property" control={<Radio color='success' />} label="Property file" />
                            <FormControlLabel value="Forex" control={<Radio color='success' />} label="FX file" />
                            <FormControlLabel value="Lender" control={<Radio color='success' />} label="Portfolio centralised file (lender)" />
                        </RadioGroup>
                    ) : 
                    (type === 'success') ? 
                    (
                        <div className='loader-container'>
                            <img src={iconSuccess} alt='Success' />
                            <br />
                            <br />
                            <span className="loader-message">Your file has been uploaded. Please note that processing the data might take up to five minutes.</span>
                        </div>
                    ) :
                    (type === 'failure') ? 
                    (
                        <div className='loader-container'>
                            {noOfValidationErrors ? <img src={iconValErrors} alt='Failure' /> : <img src={iconFailure} alt='Failure' />}
                            {
                                (failureMessage !== '') ?
                                <>
                                    <br />
                                    <br />
                                    <span className="loader-message">{failureMessage}</span>
                                </> :
                                <>
                                    <br />
                                    <span className="loader-message">
                                        {
                                            noOfValidationErrors ?
                                            <>
                                                <br />
                                                We have found <span className='colorAmber'>{noOfValidationErrors} validation errors </span>
                                                in your file. To solve this, download the attached file, fix them and re-upload.
                                            </> :
                                            <>
                                                Upload has failed. Please, try again in a few minutes.
                                            </>
                                        }
                                    </span>
                                    {failureFileName && 
                                        <Button 
                                            style={{
                                                marginTop: 16,
                                                textTransform: 'none'
                                            }}
                                            className='validationFile'>
                                                <FileCopyIcon className='iconFileDownload' />
                                                {failureFileName}
                                        </Button>
                                    }
                                </>
                            }
                        </div>
                    ) :
                    (type === 'download') ? 
                    (
                        <></>
                    ) :
                    (
                        <div className='loader-container'>
                            <Loader />
                            <br />
                            <span className="loader-message">{uploadStatus}</span>
                        </div>
                    )
                }
            </DialogContent>
            <DialogActions className={
                (type === 'upload') ?
                'flexAlignEnd' :
                'flexAlignCenter'
            }>
                { (type === 'upload') && (
                    <Button autoFocus onClick={() => upload()} className='upload-options-actions'>
                        Upload
                    </Button>
                )}
                {(type === 'success') && (
                    <Button autoFocus onClick={() => viewTableauDashboard()} className='upload-options-actions'>
                        View Tableau Dashboard
                    </Button>
                )}
                {(type === 'failure') && (noOfValidationErrors) && (
                    <>
                        <Button autoFocus onClick={() => retryUpload()} className='upload-options-actions-secondary'>
                            Re-upload
                        </Button>
                        <Button autoFocus onClick={() => downloadFile('validation')} className='upload-options-actions' disabled={isDownloadFileDisabled}>
                            Download validation file
                        </Button>
                    </>
                )}
                {(type === 'failure') && (!noOfValidationErrors) && (
                    <Button autoFocus onClick={() => retryUpload()} className='upload-options-actions'>
                        Try again
                    </Button>
                )}
                {(type === 'download') && (
                    <>
                        <Button autoFocus onClick={() => downloadFile('loan')} className='upload-options-actions custMargin' disabled={isDownloadFileDisabled}>
                            <DownloadOutlinedIcon className='downloadIcon' />
                            Loan template
                        </Button>
                        <Button autoFocus onClick={() => downloadFile('property')} className='upload-options-actions custMargin leftMargin-32' disabled={isDownloadFileDisabled}>
                            <DownloadOutlinedIcon className='downloadIcon' />
                            Property template
                        </Button>
                    </>
                )}
            </DialogActions>
            <DialogActions className={
                (type === 'upload') ?
                'flexAlignEnd' :
                'flexAlignCenter'
            }>
                 {(type === 'failure') && (!noOfValidationErrors) && (
                        <span className="loader-message">
                            Need any help?
                            <a href={snowLink} target='_blank' rel='noreferrer' className='help-link'
                            style={{ display: 'inline', alignItems: 'center', padding: '10px', fontFamily: '"Calibre-R-Regular", "Roboto", "Helvetica", "Arial", sans-serif' }}>
                                Create a support ticket
                            </a>
                        </span>
                )}
            </DialogActions>
      </BootstrapDialog>
    );
}