/**
 * Unauthorized page
 * Created By : Yogeesha Sapaliga
 * Created date : 10/10/2023
 */
import React from 'react';
import Grid from '@mui/material/Grid';

const Unauthorized = () => {
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: '5%' }}
            className='dashboard-title'
        >
            <div>Unauthorized to access Loan Services Client Reporting Portal.</div>
            <div>Please contact support team.</div>
        </Grid>
    )
}

export default Unauthorized