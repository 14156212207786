/**
 * Loading spinner
 * Created By : Yogeesha Sapaliga
 * Created date : 27/09/2023
 */
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

//  Custom imports
import './Loader.scss';

class Loader extends React.Component {
    render() {
        return (
            <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    className="lgMarginTop"
                >
                    <CircularProgress className='loader' aria-label="Progress" />
            </Grid>
        );
    }
}

export default Loader