/**
 * Configuration file to store Azure SSO details
 * Created by : Yogeesha Sapaliga
 * Created on : 22/09/2023
 */

import { LogLevel } from "@azure/msal-browser";

//  Environment variables
const auth = {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: process.env.REACT_APP_AUTH_URL,
    redirectUri: process.env.REACT_APP_REDIRECT_URL,
    grantType: 'auth_token'
};
const scopes = [ process.env.REACT_APP_SCOPES_URL ];

export const msalConfig = {
    auth: auth,
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
          loggerCallback: (level, message, containsPii) => {
            if (containsPii) {
              return;
            }
            switch (level) {
              case LogLevel.Error:
                console.error(message);
                return;
              case LogLevel.Info:
                // console.info(message);
                return;
              case LogLevel.Verbose:
                // console.debug(message);
                return;
              case LogLevel.Warning:
                // console.warn(message);
                return;
              default:
            }
          },
        },
    },
};

export const loginRequest = {
    // prompt: 'login',
    scopes: scopes,
};

export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};