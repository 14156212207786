/**
 * API service to call backend APIs
 * Created By : Yogeesha Sapaliga
 * Created date : 21/09/2023
 */
//  Local data imports
import login from '../../assets/data/login.json';
import uploadHistory from '../../assets/data/uploadHistory.json';
import uploadFile from '../../assets/data/uploadFile.json';
import processFile from '../../assets/data/processFile.json';
import downloadFile from '../../assets/data/downloadFile.json';

const apiEndPoint = process.env.REACT_APP_API_ENDPOINT;
const apiFolder = process.env.REACT_APP_API_FOLDER;

//  API Urls
const apiUrl = {
    saveLoginUserDetails: '/login',
    getUploadHistoryDetails: '/uploadhistory',
    sendFileDetails: '/upload',
    refreshTableau: '/tableaurefresh',
    processFile: '/processfile',
    getDownloadFileDetails: '/download',
    getUploadStatus: '/status',
};

const responseHandler = (response, type) => {
    const vLoc = window.location;
    switch (response.status) {
        case 0:
            return response;
        case 200:
        case 201:
        case 202:
        case 203:
        case 204:
        case 205:
        case 206:
            // return (response.statusText === 'OK') ? response : response.json();
            return (type === 'json') ? response.json() : response;
        case 400:
            return false;
        case 401:
            // vLoc.href = vLoc.protocol + '//' + vLoc.host + '/login';
            vLoc.href = vLoc.protocol + '//' + vLoc.host + '/unauth';
            return false;
        case 402:
        case 403:
            return false;
        case 404:
        case 405:
        case 406:
        case 407:
        case 408:
        case 409:
            throw new Error(response.statusText);
        case 410:
        case 411:
        case 412:
            return response;
        case 413:
        case 414:
        case 415:
            return response;
        case 416:
        case 417:
        case 500:
            // vLoc.href = vLoc.protocol + '//' + vLoc.host + '/login';
            // vLoc.href = vLoc.protocol + '//' + vLoc.host + '/unauth';
            return false;
        case 501:
        case 502:
        case 503:
        case 504:
        case 505:
            return response;
        default:
            return response;
    }
};

export default {
    saveLoginUserDetails: (varData, varHeaders) => {
        if (process.env.REACT_APP_API_POINTER.toLowerCase() === 'local') {
            return new Promise(resolve => {
                resolve(login);
            });
        } else {
            const url = apiEndPoint + apiFolder + apiUrl.saveLoginUserDetails;
            return fetch(url, {
                method: 'POST',
                headers: varHeaders,
                body: JSON.stringify(varData),
                // mode: "no-cors"
            }).then(res => {
                return responseHandler(res, 'json');
            });
        }
    },
    getUploadHistoryDetails: (varData, varHeaders) => {
        if (process.env.REACT_APP_API_POINTER.toLowerCase() === 'local') {
            return new Promise(resolve => {
                resolve(uploadHistory);
            });
        } else {
            const url = apiEndPoint + apiFolder + apiUrl.getUploadHistoryDetails;
            return fetch(url, {
                method: 'POST',
                headers: varHeaders,
                body: JSON.stringify(varData),
                // mode: "no-cors"
            }).then(res => {
                return responseHandler(res, 'json');
            });
        }
    },
    sendFileDetails: (varData, varHeaders) => {
        if (process.env.REACT_APP_API_POINTER.toLowerCase() === 'local') {
            return new Promise(resolve => {
                resolve(uploadFile);
            });
        } else {
            const url = apiEndPoint + apiFolder + apiUrl.sendFileDetails;
            return fetch(url, {
                method: 'POST',
                headers: varHeaders,
                body: varData,
                // body: JSON.stringify(varData),
                // mode: "no-cors"
            }).then(res => {
                return responseHandler(res, 'json');
            });
        }
    },
    sendFile: (varUrl, varData, varHeaders) => {
        if (process.env.REACT_APP_API_POINTER.toLowerCase() === 'local') {
            return new Promise(resolve => {
                resolve(null);
            });
        } else {
            return fetch(varUrl, {
                method: 'PUT',
                headers: varHeaders,
                body: varData,
                // body: JSON.stringify(varData),
                // mode: "no-cors"
            }).then(res => {
                return responseHandler(res, '');
            });
        }
    },
    refreshTableau: (varData, varHeaders) => {
        if (process.env.REACT_APP_API_POINTER.toLowerCase() === 'local') {
            return new Promise(resolve => {
                resolve(null);
            });
        } else {
            const url = apiEndPoint + apiFolder + apiUrl.refreshTableau;
            return fetch(url, {
                method: 'POST',
                headers: varHeaders
            }).then(res => {
                return responseHandler(res, 'json');
            });
        }
    },
    processFile: (varData, varHeaders) => {
        if (process.env.REACT_APP_API_POINTER.toLowerCase() === 'local') {
            return new Promise(resolve => {
                resolve(processFile);
            });
        } else {
            const url = apiEndPoint + apiFolder + apiUrl.processFile;
            return fetch(url, {
                method: 'POST',
                headers: varHeaders,
                body: JSON.stringify(varData)
            }).then(res => {
                return responseHandler(res, 'json');
            });
        }
    },
    getDownloadFileDetails: (varData, varHeaders) => {
        if (process.env.REACT_APP_API_POINTER.toLowerCase() === 'local') {
            return new Promise(resolve => {
                resolve(downloadFile);
            });
        } else {
            const url = apiEndPoint + apiFolder + apiUrl.getDownloadFileDetails;
            return fetch(url, {
                method: 'POST',
                body: JSON.stringify(varData),
                headers: varHeaders
            }).then(res => {
                return responseHandler(res, 'json');
            });
        }
    },
    downloadFile: (vUrl, varHeaders) => {
        if (process.env.REACT_APP_API_POINTER.toLowerCase() === 'local') {
            return new Promise(resolve => {
                resolve(null);
            });
        } else {
            return fetch(vUrl, {
                method: 'GET',
                headers: varHeaders,
                responseType: 'blob',
                observe: 'response'
            }).then(res => {
                return responseHandler(res, '');
            });
        }
    },
    getUploadStatus: (varData, varHeaders) => {
        if (process.env.REACT_APP_API_POINTER.toLowerCase() === 'local') {
            return new Promise(resolve => {
                resolve(null);
            });
        } else {
            const url = apiEndPoint + apiFolder + apiUrl.getUploadStatus;
            return fetch(url, {
                method: 'POST',
                body: JSON.stringify(varData),
                headers: varHeaders
            }).then(res => {
                return responseHandler(res, 'json');
            });
        }
    }
}