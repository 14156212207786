/**
 * Right drawer to display Upload history details
 * Created By : Yogeesha Sapaliga
 * Created date : 07/10/2023
 */
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import Button from '@mui/material/Button';
import clsx from 'clsx';

import './UploadHistory.scss';
import DataTable from '../Tables/DataTable';
import api from '../../services/api';

export default function UploadHistory(props) {
    const { uploadHistoryOpen, handleUploadHistoryOpen, handleUploadHistoryClose } = props;
    const [ isDownloadFileDisabled, setIsDownloadFileDisabled ] = useState(false);
    const [ data, setData ] = useState([]);
    const [ loading, setLoading ] = useState(false);
        
    const columns = [
        { field: 'fileName', headerName: 'File name', width: 350 },
        {
            field: 'uploadedDate',
            headerName: 'Date',
            width: 150,
            valueFormatter: params => 
                moment(params?.value).format("ddd, D MMM YYYY")
        },
        { field: 'fileType', headerName: 'File type', width: 100 },
        {
            field: 'uploadStatus',
            headerName: 'State',
            width: 150,
            cellClassName: (params) => {
                return (params.row.uploadStatus.toUpperCase().indexOf('SUCCESS') !== -1) ? 
                        clsx('colorGreen') :
                    (params.row.uploadStatus.toUpperCase().indexOf('FAILED') !== -1) ? 
                        clsx('colorRed') : 
                    clsx('colorGrey')
            }
        },
        {
            field: 'action',
            headerName: 'Validation file',
            sortable: false,
            width: 150,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                const downloadFile = (e) => {
                    const currentRow = params.row;
                    const vFileName = currentRow.objectKey;
                    const varData = {
                        'objectKey': vFileName
                    };
                    const varHeaders = {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    }
                    //  Disable Download file button
                    setIsDownloadFileDisabled(true);
                    api.getDownloadFileDetails(varData, varHeaders).then(res => {
                        setIsDownloadFileDisabled(false);
                        if (res && res.downloadPath) {
                            const vDownloadUrl = res.downloadPath;
                            const vHeaders = {
                                Accept: 'application/json',
                                'Content-Type': 'application/json'
                            };
                            api.downloadFile(vDownloadUrl, vHeaders).then(async resDownload => {
                                if (resDownload && resDownload.ok) {
                                    const vData = await resDownload.blob();
                                    const vContentType = resDownload.headers.get('content-type');
                                    const vBlob = new Blob([vData], { type: vContentType });
                                    const vUrl = window.URL.createObjectURL(vBlob);
                                    const vDownloadLink = document.createElement('a');
                                    vDownloadLink.href = vUrl;
                                    let vFinalFileName = vFileName;
                                    if (vFileName.indexOf('/') !== -1) {
                                        const vFileNameFull = vFileName.split('/');
                                        vFinalFileName = vFileNameFull[vFileNameFull.length-1];
                                    }
                                    vDownloadLink.download = vFinalFileName;
                                    vDownloadLink.click();
                                    setIsDownloadFileDisabled(false);
                                } else {
                                    console.log('ERROR: Downloading file');
                                    setIsDownloadFileDisabled(false);
                                }
                            }).catch(error => {
                                console.log('ERROR: Downloading file ', error);
                                setIsDownloadFileDisabled(false);
                            })
                        } else {
                            console.log('ERROR: Download file details');
                            setIsDownloadFileDisabled(false);
                        }
                    }).catch(error => {
                        console.log('ERROR: Download file details ', error);
                        setIsDownloadFileDisabled(false);
                    });
                }
                return (
                    <>
                    {
                        (params.row.objectKey !== '') ?
                        <Button variant="outlined" onClick={() => downloadFile()}
                            style={{
                                textTransform: 'none',
                                fontSize: 16,
                                fontStyle: 'normal',
                                fontWeight: '500',
                                lineHeight: '16px',
                                borderRadius: 4,
                                padding: '5px 12px'
                            }}
                            className='btnDownload'>
                                Download
                        </Button> :
                        '-'
                    }
                    </>
                )
            }
        }
    ];

    useEffect(() => {
        if (uploadHistoryOpen) {
            getUploadHistory();
        }
    }, [uploadHistoryOpen]);
    
    const getUploadHistory = () => {
        //  API call to get Upload history details
        const varData = {};
        setData([]);
        setLoading(true);
        api.getUploadHistoryDetails(varData,
            {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        ).then(res => {
            setLoading(false);
            let results = [];
            if (res && (res.length > 0)) {
                results = res;
                for (let i = 0; i < results.length; i++) {
                    results[i]['id'] = i;
                }
            }
            setData(results);
        }).catch(error => {
            setLoading(false);
            console.log('Error occurred while fetching upload history details.');
        });
    };

    return (
        <SwipeableDrawer
            anchor={'right'}
            open={uploadHistoryOpen}
            onClose={handleUploadHistoryClose}
            onOpen={handleUploadHistoryOpen}
          >
            <Box
                sx={{ width: 1000 }}
                role="presentation"
                style={{ padding: 30 }}
                >
                <div className='upload-history-title' style={{ paddingTop: 30 }}>
                    <Grid container spacing={2}>
                        <Grid item md={6} sm={12} data-testid=''>
                            Upload history
                        </Grid>
                        <Grid item md={6} sm={12} className='align-right'>
                            <IconButton
                                aria-label="close"
                                onClick={() => {handleUploadHistoryClose()}}
                                sx={{
                                    color: (theme) => theme.palette.grey[500],
                                }}
                                className="closeIcon"
                                disabled={isDownloadFileDisabled}
                            >
                            <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </div>
                <DataTable rows={data} columns={columns} loading={loading} />
            </Box>
        </SwipeableDrawer>
    );
}