import React, { useMemo } from 'react';
import Grid from '@mui/material/Grid';
const ButtonMenu = ({ isDrawerOpen, icon, titleText, linkHref }) => {
  const linkStyle = useMemo(
    () => ({
      width: 55,
      height: 55,
      marginLeft: 25,
      marginBottom: 7,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'left',
    }),
    []
  );
  return (
    <a
      href={linkHref}
      target='_blank'
      rel='noreferrer'
      className='help-link drawer-link'
      style={!isDrawerOpen ? linkStyle : { ...linkStyle, width: 185 }}
    >
      {!isDrawerOpen ? (
       icon
      ) : (
        <Grid style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {icon}
          <p style={{ marginLeft: 7, fontFamily:'Calibre-R-Regular' }}>{titleText}</p>
        </Grid>
      )}
    </a>
  );
};

export default ButtonMenu;
