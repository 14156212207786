/**
 * Button Sign in with SSO
 * Created By : Yogeesha Sapaliga
 * Created date : 21/09/2023
 */
import Button from '@mui/material/Button';

//  Internal imports
import './ButtonCommon.scss';

const ButtonCommon = (props) => {
    return (
        <Button
            className='signin-button mt-4'
            onClick={() => props.onClick()}
            disabled={props.disabled}>
                Sign in with SSO
        </Button>
    )
}

export default ButtonCommon;