import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import './index.scss';
import App from './App';

// replace console.* for disable log on production
if (
  (process.env.NODE_ENV === 'production') ||
  (process.env.NODE_ENV === 'stage') ||
  (process.env.NODE_ENV === 'test') ||
  (process.env.NODE_ENV === 'development')
) {
  //console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals())
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
