/**
 * App Component of the application
 * 1. Check if user authenticated then display Authenticated pages
 * 2. If not authenticated display login page
 * Created By : Yogeesha Sapaliga
 * Created date : 18/09/2023
 */
import './App.scss';
import { useEffect } from 'react';
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { Route, BrowserRouter as Router, Routes, Navigate } from 'react-router-dom';

import { msalConfig, loginRequest } from './authConfig';
import Login from './components/pages/login/Login';
import Dashboard from './components/pages/dashboard/Dashboard';
import Unauthorized from './components/pages/errors/Unauthorized';
import PDFViewer from './components/pages/help/help';
const pca = new PublicClientApplication(msalConfig);

const routing = (
  <Router>
    <div>
      <Routes>
        <Route path="/" element={<Navigate to="/login" replace={true} />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/dashboard" element={<Dashboard />} />
        <Route exact path="/help" element={<PDFViewer />} />
        <Route exact path="/unauth" element={<Unauthorized />} />
        <Route element={<Unauthorized />} />
      </Routes>
    </div>
  </Router>
);

const App = () => {
  document.title = 'CBRE Loan Analytics Portal';

  useEffect(() => {
    //  Intercept backend requests to append authorization bearer token
    interceptRequests();
  }, []);

  /**
   * Function to intercept requests to append authorization bearer token
   */
  const interceptRequests = () => {
    const { fetch: originalFetch } = window;
    window.fetch = async (...args) => {
      let [resource, config] = args;
      const vResUrl = resource.toUpperCase();
      const vApiUrl = process.env.REACT_APP_API_ENDPOINT.toUpperCase();
      if (vResUrl.indexOf(vApiUrl) !== -1) {
        //  Get the auth token
        const vToken = await getAuthToken();
        //  Append auth token to request authorization header
        config.headers['Authorization'] = 'Bearer ' + vToken;
      }
      const response = await originalFetch(resource, config);
      return response;
    };
  }

  /**
   * Function to get auth token
   * @returns token
   */
  const getAuthToken = async () => {
    await pca.initialize();
    const responseData = await pca.acquireTokenSilent({
      ...loginRequest,
      account: pca.getAllAccounts()[0],
    });
    const token = responseData.accessToken;
    return token;
  };

  return (
    <MsalProvider instance={pca}>
      {routing}
    </MsalProvider>
  );
}

export default App;
